import {BrowserRouter, Route, Routes, } from "react-router-dom";

import Room from './components/Rooms';
import Home from './components/Home';

function App() {
  

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home/>}></Route>
          <Route path="/room" element={<Home/>}></Route>
          <Route path="/room/:roomID" element={<Room/>}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  )
} 

export default App