import {useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";

import '../index.css';
import 'mdb-react-ui-kit/dist/css/mdb.dark.min.css'

import {
    MDBBtn,
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBInput
  } from 'mdb-react-ui-kit';

const Home = () => {
    const navigate = useNavigate()
    const [inputDisabled, setInputDisabled] = useState(true);
    const location = useLocation()
    const roomRef = useRef();

    const errorRef = useRef();

    const handleNav = () => {
        let room = roomRef.current.value
        console.log("Entering room... " + room);
        navigate("/room/"+room)

        // window.partnerVid = partnerVideo
        // partnerVideo.current.srcObject = e.streams[0]
        // partnerVideoStream.current = e.streams[0]
        // peerRef.current.getTracks().forEach( 
        //     (track) =>  {
        //       ColabPeerRef.current.addTrack( track, ColabUserVideoStream.current );
        //     }
        //   );
        // peerRef.current.addTrack(e.streams[0])
        // partnerVideo.current.srcObject = e.streams;
    };

    const handleInput  = () => {
        let room = roomRef.current.value
        if (room.length > 0) {
            setInputDisabled(false)
        } else {
            setInputDisabled(true)
        }
    }

    useEffect(()=>{
        // console.log(location.state.error)
        if (location.state != undefined && location.state.error != undefined) {
            errorRef.current = location.state.error
        }
    },[])

  return (
    <MDBContainer className="gradient-form">
        <MDBRow>
            <MDBCol md='4' className="col-md-4 mb-5">

            </MDBCol>
            <MDBCol md='4' className="col-md-4 mb-5">
                <div className="d-flex flex-column">
                    <div className="logo mt-12"></div>
                    <br></br>
                    <h3>Collaboration Mode</h3>
                    <h5 className="mb-5">Enter Room ID</h5>
                    {
                        location.state != undefined && location.state.error != undefined ? 
                        <p className='error-msg'>
                            {location.state.error}
                        </p>
                        : null
                    }
                    <MDBInput ref={roomRef} label='Room ID' maxLength={8} className='upper-input' onInputCapture={handleInput} id='form1' type='text' />
                    <br></br>
                    <div className="text-center pt-1 mb-5 pb-1">
                        <MDBBtn className="mb-4 w-100 daqa-btn" disabled={inputDisabled} onClick={() => { handleNav() }}>Continue</MDBBtn>
                    </div>
                </div>
            </MDBCol>
            <MDBCol md='4' className="col-md-4 mb-5">

            </MDBCol>

        </MDBRow>

    </MDBContainer>
  )
}

export default Home;